import { template as template_4361ff856f4d4d2a8b6bd337aea6959d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4361ff856f4d4d2a8b6bd337aea6959d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
