import { template as template_52e7a459f7b54221a79c8c0ae6fd1f95 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_52e7a459f7b54221a79c8c0ae6fd1f95(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
