import { template as template_41fb16e2c393481d84f697c57c5e3cf9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_41fb16e2c393481d84f697c57c5e3cf9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
