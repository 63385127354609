import { template as template_db5b9d8f34574c88aa9ca82c6a4d84c4 } from "@ember/template-compiler";
const FKLabel = template_db5b9d8f34574c88aa9ca82c6a4d84c4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
