import { template as template_93111a513d3c4580bd7c8394079e7a40 } from "@ember/template-compiler";
const FKText = template_93111a513d3c4580bd7c8394079e7a40(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
